import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './components/home/Home';

function App() {
  return (
    <Home></Home>
  );
}

export default App;
