// Home.js
import React from 'react';
import ImageGallery from '../imagegalary/ImageGallery';
import ContactForm from '../contactform/ContactForm';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import styles from './Home.module.css';

import appImage from '../../images/app_.jpg'
import appImageAdj from '../../images/app_adj.jpg'

const Home = () => {
  return (
    <div className="container">
      {/* <ToastContainer /> */}
      <section className="row mb-5">
        <div className="col">
          <Header />
        </div>
      </section>

      {/* <section className="mb-5">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <img src={logoImg} alt="" className="img-fluid" />
          </div>
        </div>
      </section> */}

      <section className="row mb-10">
        <div className={styles.description}>
          <p>Introducing prismLAMP - a fusion of modern LED tech and classic elegance. Handcrafted with care, it boasts a stunning glass shade atop a sturdy wooden base. With its intuitive mobile app, controlling prismLAMP is a breeze.</p>
        </div>
      </section>

      <section className="row mb-10">
        <div className="col-md-6 align-items-center">
          <div className={styles.title}>
            <p>Simple as possible</p>
          </div>
          <div className={styles.description}>
            <p>Handcrafted with care, it boasts a stunning glass shade atop a sturdy wooden base. With its intuitive mobile app, controlling prismLAMP is a breeze. Choose from 50+ dynamic visual effects, adjust brightness, speed, and find the perfect ambiance for any occasion - from cozy nights to vibrant gatherings.</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.imgContainer}>
            <img src="https://img001.prntscr.com/file/img001/LzsfFvDwTI2ZGcrBC8ot0g.png" alt="" className="img-fluid" />
          </div>
        </div>
        
      </section>

      <section className="row mb-10">
        <div className="col">
          <div className={styles.title}>
            <p>50+ dynamic visual effects</p>
          </div>
          
          {/* <div className={styles.description}>
            <p>Introducing prismLAMP LED Lamp - a fusion of modern LED tech and classic elegance. Handcrafted with care, it boasts a stunning glass shade atop a sturdy wooden base. With its intuitive mobile app, controlling prismLAMP is a breeze. Choose from 50+ dynamic visual effects, adjust brightness, speed, and find the perfect ambiance for any occasion - from cozy nights to vibrant gatherings. Illuminate your space with prismLAMP - it's more than just a light, it's an expression of your style.</p>
          </div> */}
          <ImageGallery />
        </div>
      </section>

      <section className="row mb-10">
        <div className='row'>
          <div className={styles.title}>
            <p>Simple mobile applocation</p>
          </div>
        </div>
        <div className="col-md-6 flex">
        {/* `form-control round-lg ${this.state.valid ? '' : 'error'}` */}
          <div className={`m-3 ${styles.imgContainer}`}>
            <img src={appImage} alt="" className="img-fluid" />
          </div>
          <div className={`m-3 ${styles.imgContainer}`}>
            <img src={appImageAdj} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-md-6 align-items-center">
          <div className={styles.description}>
            <ul>
              <li>Simple mobile app for easy control and navigation.</li>
              <li>Choose from 50+ dynamic visual effects, from gentle gradients to captivating patterns.</li>
              <li>Adjustable brightness, speed, and variation for customizable ambiance.</li>
              <li>Enjoy the awesome sunrise alarm feature for a gentle wake-up experience.</li>
              <li>Instant response ensures smooth and seamless user interaction.</li>
            </ul>
          </div>
        </div>
        
      </section>


      <section className="row mb-10">
        <div className="col">
          <div className={styles.title}>
            <p>Pre-order</p>
          </div>
          <ContactForm />
        </div>
      </section>
      {/* <section>
        <Price />
      </section> */}
      <section className="row mb-10">
        <div className="col">
          <Footer />
        </div>
      </section>
    </div>
  );
}

export default Home;
