// ImageGallery.js
import React from 'react';
import styles from './ImageGallery.module.css';

const ImageGallery = () => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className={styles.imgContainer}>
          <img src="https://img001.prntscr.com/file/img001/LzsfFvDwTI2ZGcrBC8ot0g.png" alt="" className="img-fluid mb-4" />
        </div>
      </div>
      <div className="col-md-4">
        <div className={styles.imgContainer}>
          <img src="https://img001.prntscr.com/file/img001/LzsfFvDwTI2ZGcrBC8ot0g.png" alt="" className="img-fluid mb-4" />
        </div>
      </div>
      <div className="col-md-4">
        <div className={styles.imgContainer}>
          <img src="https://img001.prntscr.com/file/img001/LzsfFvDwTI2ZGcrBC8ot0g.png" alt="" className="img-fluid mb-4" />
        </div>
      </div>
    </div>
  );
}

export default ImageGallery;