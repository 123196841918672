// Header.js
import React from 'react';
import styles from './Header.module.css';

const Header = () => {

  const onClickHandler = () => {
    document.getElementById('form').scrollIntoView();
  }

  return (
    <header className={styles.header}>

      <div className={styles.siteLabel}>🔥 prismLAMP</div>
      <div className={styles.headerButton}>
        <button disabled={false} type="button" class="btn btn-secondary btn-lg" onClick={onClickHandler} >Pre-order</button>
      </div>
      
    </header>
  );
}

export default Header;
