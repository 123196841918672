import { useState, React }  from 'react';
import styles from './ContactForm.module.css';

const ContactForm = () => {
	const [isPolicy, setIsPolicy] = useState(false);

	const handleClick = () => setIsPolicy( ! isPolicy);

	const sendMessage = async () => {
		console.log('test');
		// fetch('https://api.telegram.org/bot5596035845:AAGJHGXIf_OoFGwFOecyX6xRXUI2e5-fps4/sendMessage?chat_id=2421813&text=test', {
		// 	method: 'POST'
		// })
	}

	const notify = async () => {
		await sendMessage();
		// toast("Default Notification !");
	};

	return (
		<div className={styles.form} id='form'>
			<div className={styles.formInput}>
				<label for="formGroupExampleInput">Your name</label>
				<input type="text" />
			</div>
			<div className={styles.formInput}>
				<label for="formGroupExampleInput">Your address country</label>
				<input type="text" />
			</div>
			<div className={styles.formInput}>
				<label for="formGroupExampleInput2">Email address</label>
				<input type="email" />
			</div>
			
			<div class="form-check">
				<input class="form-check-input" type="checkbox" id="gridCheck" checked={isPolicy} onClick={handleClick} />
				<label class="form-check-label" for="gridCheck">
					I Agree to &nbsp;
					<a href='https://www.instagram.com/prismlamp'>
						Privacy Policy
					</a>
				</label>
			</div>
			<button onClick={notify} disabled={!isPolicy} type="button" class="btn btn-secondary btn-lg">Send a request</button>
		</div>
	);
}

export default ContactForm;
