// components/Footer.js
import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <a href="https://www.instagram.com/prismlamp">Follow us on Instagram</a>
    </footer>
  );
}

export default Footer;
